import React, { useState, useEffect, useRef  } from 'react';
import Visualizer from './Visualizer';
import './App.css';
import { FaSpinner,FaPlay, FaPause } from 'react-icons/fa';

function App() {
  const [songs, setSongs] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isToggled, setIsToggled] = useState(false);  
  const seekToRef = useRef(null);

  useEffect(() => {
    const loadInitialSongs = () => {
      if (isToggled) {
        setSongs([
          { title: 'wasup dr. dre', src: require('./uploads/wasup4.mp3')},
          { title: 'bagdrop', src: require('./uploads/droppedbag5.mp3')},
          { title: 'major way', src: require('./uploads/major6.mp3')},
        ]);
      } else {
        setSongs([
          { title: 'regulata', src: require('./uploads/regulata4.mp3')},
          { title: 'on & on', src: require('./uploads/onandon3.mp3')},
          { title: 'stop dancing', src: require('./uploads/coollead4.mp3')},
        ]);
      }
    };
  
    loadInitialSongs();
  }, [isToggled]);
  

  const handleSeek = (seekTime) => {
    if (seekToRef.current) {
      seekToRef.current(seekTime);
      setCurrentTime(seekTime);
    }
  };
  const handleSongClick = (song) => {
    if (currentSong === song.src) {
      setIsPlaying(!isPlaying);
    } else {
      setCurrentSong(song.src);
      setIsPlaying(true);
      setCurrentTime(0);
      setDuration(0); // Reset duration to 0 when a new song is clicked
    }
  };

  const toggleTracks = () => {
    const header = document.querySelector('.song-list h2');
  
    // Add the animation class
    header.classList.add('animate');
  
    // Wait for the animation to complete before toggling the state
    setTimeout(() => {
      setIsToggled(!isToggled);
  
      // Remove the animation class after it completes
      header.classList.remove('animate');
    }, 1000); // Match this duration with your keyframes animation duration
  };
  
  

  return (
    <div className="App">
      <main className="App-main">
        <section className="song-list">
          <h2 onClick={toggleTracks} style={{ cursor: 'pointer', transition: 'color 0.3s' }}>
            {isToggled ? 'more tracks' : 'tracks'}
          </h2>
          <ul>
            {songs.map((song, index) => (
              <li key={index} className="song-item" onClick={() => handleSongClick(song)}>
                <span>{song.title}</span>
                {currentSong === song.src && (
                  <SongProgressBar 
                    currentTime={currentTime} 
                    duration={duration} 
                    onSeek={handleSeek} 
                  />
                )}
                {currentSong === song.src && isPlaying ? (
                  <FaPause className="icon" />
                ) : (
                  <FaPlay className="icon" />
                )}
              </li>
            ))}
          </ul>
        </section>
        <footer>
          <div className="visualizer-container">
            {currentSong && (
              <Visualizer
                src={currentSong}
                setCurrentTime={throttleSetCurrentTime(setCurrentTime)}
                setDuration={setDuration}
                isPlaying={isPlaying}
                currentTime={currentTime}
                provideSeek={(seekTo) => (seekToRef.current = seekTo)} // This line passes the seekTo method
              />
            )}
          </div>
          <p>
          <a 
            href="https://soundcloud.com/samcclement" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ color: 'white', textDecoration: 'none' }}
          >
            ©2024 coopa
          </a>
        </p>
          <p>for higher-quality downloads, contact samclement@berkeley.edu</p>
        </footer>
      </main>
    </div>
  );
}

const throttleSetCurrentTime = (setCurrentTime, limit = 100) => {
  let lastCall = 0;
  return (time) => {
    const now = Date.now();
    if (now - lastCall >= limit) {
      lastCall = now;
      setCurrentTime(time);
    }
  };
};

const SongProgressBar = ({ currentTime, duration, onSeek }) => {
  const [isDurationLoaded, setIsDurationLoaded] = useState(false);

  useEffect(() => {
    // Reset the loading state when a new song is selected
    setIsDurationLoaded(false);
  }, [duration]);

  useEffect(() => {
    if (duration > 0) {
      setIsDurationLoaded(true);
    }
  }, [duration]);

  const formatTime = (time) => {
    if (isNaN(time)) return '0:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const handleSeek = (event) => {
    event.stopPropagation();
    const { left, width } = event.target.getBoundingClientRect();
    const clickPosition = event.clientX - left;
    const seekTime = (clickPosition / width) * duration;
    onSeek(seekTime);
  };

  return (
    <div className="song-progress-bar" onClick={handleSeek}>
      <span>{formatTime(currentTime)}</span>
      <input
        type="range"
        value={currentTime}
        max={duration || 0}
        readOnly
        style={{ margin: '0 10px' }}
      />
      <span>
        {isDurationLoaded ? (
          formatTime(duration)
        ) : (
          <FaSpinner className="spinner-icon" />
        )}
      </span>
    </div>
  );
};


export default App;
